<template>    
    <div>
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat dense class="tc-title">
            <v-toolbar-title>New Question</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
        <v-container fluid>
            <v-row align="center">
                <v-col class="d-flex" cols="12" sm="4">
                    <v-select
                    :items="company_list"
                    item-value="id"
                    item-text="name"
                    label="Choose Company"
                    outlined
                    v-model="selected_company"
                    ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="4">
                    <v-select
                        :items="round_list"
                        item-value="id"
                        item-text="name"
                        label="Round"
                        outlined
                        v-model="selected_round"
                    ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="4">
                    <v-select
                    :items="type_list"
                    item-value="id"
                    item-text="name"
                    v-model="selected_type"
                    outlined
                    label="Question Type"
                    @change="fetchquestiontype()"
                    ></v-select>
                </v-col>
            </v-row>
            <div v-show="selected_div != ''">
                 <v-toolbar  flat dense class="tc-title">
                    <v-toolbar-title>{{selected_div}}</v-toolbar-title>
                </v-toolbar>
                <v-row >
                    <v-col cols="12" sm="12">
                        <v-text-field outlined label="Question Statement" required v-model="question_stmt"></v-text-field>
                    </v-col> 
                </v-row>
            </div>

            <div v-show="selected_div === 'single word'">
                    <div v-for="(input, index) in inputs" :key="index">
                    
                        <v-col cols="12" sm="6" >
                        <v-label>Option {{index+1}}</v-label>
                            <v-row>
                                <v-checkbox input type="checkbox" v-model="input.value">  </v-checkbox>
                                <v-text-field type="text" outlined v-model="input.text">    </v-text-field>
                                <v-btn color="red" fab small dark @click="deleteRow(index)" class="del">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-row> 
                        </v-col>  
                    </div>
                    <div v-show="this.optionsize <=4"> 
                        <v-btn color="primary" class="btn-title" fab small dark @click="addRow">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>
                     <center>
                        <v-btn color="warning" class="btn-title del" @click="savequestionMCQ" dark>Save</v-btn>
                    </center>  
            </div>
            <div v-show="selected_div === 'Descriptive'">
                <v-row >
                    <v-col cols="12" sm="12">
                        <v-textarea outlined label="Question Answer" required v-model="question_data"></v-textarea>
                    </v-col> 
                    <v-col cols="12" sm="12" align="center">
                        <v-btn color="warning" class="btn-title del" @click="savequestion"  dark>Save</v-btn>
                    </v-col>  
                </v-row>
            </div>
            <div v-show="selected_div === 'Image'">
                <v-row>
                <v-col cols="12" sm="8">
                        <v-file-input
                            accept=".jpg,"
                            label="Choose image (max size: 2MB)"
                            outlined
                            show-size
                            v-model="QuestionImageFile"
                        >
                        </v-file-input>
                </v-col>   
                <v-col cols="12" sm="4">
                    <v-btn color="warning" @click="savequestion" class="btn-title del" dark>Upload</v-btn>
                </v-col>    
                </v-row>   
            </div>
            <br>
            <template v-if="this.question_list!=null">
                <v-toolbar flat dense class="card-title">
                    <v-toolbar-title>Already Added Questions</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-simple-table responsive>
                    <tr>
                        <th class="text-left">Question Statement</th>
                        <th class="text-left">Question Answer</th>
                        <th class="text-left">Company Round</th>
                        <th class="text-left">Question Type</th>
                        <th class="text-left">Image File</th>
                        <th class="text-left">Actions</th>
                    </tr>
                    <tbody>
                        <tr v-for="que in this.question_list" :key="que[0].id">
                            <td>
                                {{ que[0].question_stmt}}
                            </td>
                            <td>
                                {{ que[0].question_answer}}
                            </td>
                            <td>
                                {{ que[0].question_company_round}}
                            </td>
                            <td>
                                {{ que[0].question_type}}
                            </td>
                            <td v-if="que[0].quefile=='NA'">{{ que[0].quefile}}</td>
                             <td v-else><a v-bind:href="que[0].quefile" target="blank" class="alink"><v-icon color="success" >mdi-cloud-download</v-icon></a></td>
                            <td v-if="que[0].isapproved == true">
                                <v-avatar style="" size="25" class="delete-avatar">
                                    <v-icon class="delete-v-icon mr-2" @click="deleteItem(que[0].id)">delete</v-icon>
                                </v-avatar>                         
                            </td>
                            <td v-else>
                             <v-avatar style="" size="25" class="edit-avatar">
                                    <v-icon class="edit-v-icon mr-2" @click="editItem(que[0])">edit</v-icon>
                                </v-avatar>
                                <v-avatar style="" size="25" class="delete-avatar">
                                    <v-icon class="delete-v-icon mr-2" @click="deleteItem(que[0].id)">delete</v-icon>
                                </v-avatar>  
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </template>
            
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="900px" lazy absolute>
        <v-card>
          <template v-if="this.selectedQuestion!=null && this.selectfor=='view'">      
            <v-card-title
              class="headline grey lighten-2"
              primary-title >
              {{this.selectedQuestion.question_stmt}} 
        
            </v-card-title>
            <v-card-text>
              {{this.selectedQuestion.question_answer}}
            </v-card-text>
            <template v-if="this.selectedQuestion.question_type === 'single word'">
              <div v-for="opt in this.selectedQuestion.option_list" :key="opt.id">
                <v-card-text>
                  <template v-if="opt.iscorrect===true">
                    <v-checkbox readonly v-model="opt.iscorrect" v-bind:label="`${opt.option_name} ] ${opt.option_value}`"></v-checkbox>
                  </template>
                  <template v-if="opt.iscorrect===false">
                    <v-checkbox readonly v-model="opt.iscorrect" v-bind:label="`${opt.option_name} ] ${opt.option_value}`"></v-checkbox>
                  </template>
                </v-card-text>
              </div>
            </template>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </template>
          <template v-if="selectedQuestion!=null && selectfor=='edit'">      
            
            <v-card-title
              class="headline grey lighten-2"
              primary-title >
              Edit Question{{selectedQuestion.question_type}} 
        
            </v-card-title>
            
            <v-row >

            <v-col cols="1" sm="1">
            </v-col>
            <v-col cols="12" sm="10">
            <div  v-show="selectedQuestion.question_type !='Image'">
                <v-row >
                    <v-col cols="12" sm="10">
                        <v-text-field outlined label="Question Statement" required v-model="selQuestionStmt" type="text"></v-text-field>
                    </v-col> 
                </v-row>
            </div>
            <div v-show="selectedQuestion.question_type === 'single word'">
                <div id="app">
                    <div v-for="(input, index) in inputs" :key="index">
                        <v-col cols="12" sm="8">
                        <v-label>Option {{index+1}}</v-label>
                            <v-row>
                                
                                <v-checkbox input type="checkbox" v-model="input.value">  </v-checkbox>

                                <v-text-field type="text" outlined v-model="input.text"> </v-text-field>
                                
                        <v-col cols="1" sm="1"> </v-col> 
                                <v-btn color="primary" @click="deleteRow(input.text)"><v-icon>mdi-delete</v-icon></v-btn>
                                
                            </v-row> 
                        
                        </v-col>  
                    </div>
                    <!-- <v-icon color="primary" class="add-v-icon mr-2" @click="addRow">add </v-icon>Add Option -->
                    <div v-show="optionsize <= 4">
                        <v-btn color="primary" @click="addRow" class="btn-title">ADD Options</v-btn>
                    </div>
                </div>
            </div> 
            <div v-show="selectedQuestion.question_type === 'Descriptive'">
                <v-row >
                    <v-col cols="10" sm="10">
                        <v-text-field outlined label="Question Answer" required v-model="selQuestionAnswer"  type="text"></v-text-field>
                    </v-col> 
                </v-row>
            </div>
            <div v-show="selectedQuestion.question_type === 'Image'">
                <v-col cols="10" sm="10">
                    <v-card-text>
                        <v-file-input
                            accept=".jpg,"
                            label="Choose image (max size: 2MB)"
                            outlined
                            show-size
                            v-model="QuestionImageFile"
                        >
                        </v-file-input>
                    </v-card-text>
                </v-col>          
            </div>
            </v-col> 
            <v-col cols="1" sm="1">
            </v-col>
            </v-row>          
            <v-divider></v-divider>
            <v-card-actions>
              
              <v-btn
                color="primary"
                @click="dialog = false"
              >
                Close
              </v-btn>
             <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="editquestions"
              >
                Edit
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-dialog>
    </v-layout>    
</v-container>   
</v-card>

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_up"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please Wait !!!
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({  
            optionsize:0,
            inputs: [],
            dialog_up:false,
            selectedQuestion:null,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            search:'',
            edit_btn_load:false,
            selected_company:null,
            selected_round:null,
            selected_type:'',
            selected_div:'',
            question_data:null,
            question_stmt:null,
            QuestionImageFile:null,
            questiondata:'',
            dialog: false,
            selected:null,
            selectfor:null,
            selected_all:'',
            question_list:null,
            company_list:null,
            round_list:null,
            type_list:null,
            status_list:null,
            selQuestionStmt:null,
            selQuestionAnswer:null,
            selQuestionId:null,

            editedIndex: -1,
            editedItem: {
                company: '',
                round: '',
                type: '',
                status: '',
            },
            defaultItem: {
            company: '',
                round: '',
                type: '',
                status: '',
            },
        }),
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
            },
        },

        watch: {
            dialog (val) {
                val || this.close()
            },
        },
        methods: {
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            updateEmployee (value,authorityid) {
                this.selected_emp.push(value);
                this.selected_emp.push(authorityid);
            },
            addquestions(){
                this.$router.push("/student-addquestion")
            },
            addRow() {
                this.inputs.push({
                    value: '',
                    text: '',
                    id: ''
                })
                this.optionsize++
            },
            deleteRow(index) {
                //alert("option "+index)
               // this.inputs.splice(index,1)
              //  this.optionsize--
                var data={
                    option :index,                  
                    selQuestionId : this.selectedQuestion.id,
                    selQuestionStmt :this.selectedQuestion.question_stmt
                }
                 axios
                        .post("/LearnerData/deleteoption", data)
                        .then(res => {
                        //window.console.log(res);
                        if (res.data.code == "200") {
                            this.dialog=false;
                              this.showSnackbar("#4caf50",res.data.message)
                              this.onload()
                        } else {                          
                            this.message = res.data.message;                         
                        }
                        })
                        .catch(error => {
                        //window.console.log("error:" + error);
                        })
                        .finally(() => (this.fetch_org_load = false));

            },
            fetchquestiontype()
            {
                for(let x in this.type_list){
                        if(this.type_list[x].id == this.selected_type){
                            this.selected_div=this.type_list[x].name
                            if(this.selected_div=="single word"){
                                this.inputs.push({
                                    value: '',
                                    text: '',
                                    id: '',
                                })
                                this.optionsize++
                            }
                            break
                        }
                    }
            }, 
            checkFile()
            {

            },
            viewItem(item)
            {

            },
            editItem(item) {
                this.selectedQuestion = Object.assign({}, item)
                this.selectfor="edit"
                this.inputs=[]
                this.optionsize=0
                this.selQuestionId=this.selectedQuestion.id
                this.selQuestionStmt=this.selectedQuestion.question_stmt
                this.selQuestionAnswer=this.selectedQuestion.question_answer
                for(let x in this.selectedQuestion.option_list){
                this.inputs.push({
                    value: this.selectedQuestion.option_list[x].iscorrect,
                    text: this.selectedQuestion.option_list[x].option_value,
                    id: this.selectedQuestion.option_list[x].id,
                })
                this.optionsize++
                    
                }
                this.dialog = true
            },
            editquestions()
                {
                    //alert(this.selQuestionStmt)
                    const data = {
                    optionlist:this.inputs,  
                    question_id:this.selectedQuestion.id,
                    question_answer:this.selQuestionAnswer,
                    question_stmt:this.selQuestionStmt,
                    question_type:this.selectedQuestion.question_type.name,
                    };
                    axios
                        .post("/LearnerData/EditQuestionBank", data)
                        .then(res => {
                        //window.console.log(res);
                        if (res.data.code == "200") {
                             this.dialog = false;
                              this.showSnackbar("#4caf50",res.data.msg);
                        } else {                          
                            this.message = res.data.msg;                         
                        }
                        })
                        .catch(error => {
                        //window.console.log("error:" + error);
                        })
                        .finally(() => (this.fetch_org_load = false));
                },
            editElementItem(item)
            {
                const data = {
                    question_id:item,
                };
                axios
                    .post("/LearnerData/editQuestion", data)
                    .then(res => {
                    //window.console.log(res);
                    if (res.data.msg == "200") {
                        this.question_list = res.data.question_list;
                        this.courseauthoritylevels=res.data.courseauthoritylevels;
                        this.showSnackbar("#4caf50",res.data.message);
                    } else {
                        this.message = res.data.message;
                    }
                    })
                    .catch(error => {
                    //window.console.log("error:" + error);
                    })
                    .finally(() => (this.fetch_org_load = false));
            },
            deleteItem(item)
            {
                const data = {
                    question_id:item,
                };
                if(confirm("Do you really want to delete?")){
                axios
                    .post("/LearnerData/deleteQuestion", data)
                    .then(res => {
                    //window.console.log(res);
                    if (res.data.msg == "200") {
                        this.questiondata = res.data.question_list;
                        this.question_list = res.data.question_list;
                        //this.courseauthoritylevels=res.data.courseauthoritylevels;
                        this.showSnackbar("#4caf50",res.data.message);
                        this.onload()
                    } else {
                        this.message = res.data.message;
                    }
                    })
                    .catch(error => {
                    //window.console.log("error:" + error);
                    })
                    .finally(() => (this.fetch_org_load = false));
                }
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            savequestion()
            {
                ///alert("Call"+this.inputs.value)
                if(this.selected_company==null)
                {
                    this.showSnackbar("#a61300","Select Company !!!");
                    //alert("Select Company")

                }else if(this.selected_round==null)
                {
                    //alert("Select Round")
                    this.showSnackbar("#a61300","Select Round !!!");
                }
                else if(this.question_stmt==null){
                    this.showSnackbar("#a61300","Enter Question Statement !!!");    

                }
                else{
                var formData = new FormData();
                const data = {

                    // optionlist:this.inputs,
                    company_id:this.selected_company,
                    question_type_id:this.selected_type,
                    company_round_id:this.selected_round,
                    question_stmt:this.question_stmt,
                    question_data:this.question_data,
                };
                //alert("round : "+this.selected_round)
                //console.log("round"+this.selected_round);
                    //formData.append(optionlist,this.inputs);  
                    formData.append("company_id",this.selected_company);
                    formData.append("company_round_id",this.selected_round);
                    formData.append("question_type_id",this.selected_type);
                    formData.append("question_stmt",this.question_stmt);
                    formData.append("question_data",this.question_data);
                     formData.append("qfile", this.QuestionImageFile);
                 // console.log("round1 : "+this.selected_round); 
                if(this.QuestionImageFile!=null){
                   // alert("File Upload")
                   this.dialog_up=true
                axios
                        .post("/LearnerData/saveImgQuestion", formData,{
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
                    .then(res => {
                    //window.console.log(res);
                    if (res.data.msg == "200") {
                        this.questiondata = res.data.question_list;
                        this.question_list = res.data.question_list;
                        //this.courseauthoritylevels=res.data.courseauthoritylevels;
                        this.showSnackbar("#4caf50",res.data.message);
                        this.QuestionImageFile=null
                        this.question_stmt=null
                        this.dialog_up=false
                        this.onload()
                    } else {
                        this.message = res.data.message;
                    }
                    })
                    .catch(error => {
                    //window.console.log("error:" + error);
                    })
                    .finally(() => (this.fetch_org_load = false));
                }
                else{
                    
                   axios
                        .post("/LearnerData/saveQuestion", data)
                    .then(res => {
                    window.console.log(res);
                    if (res.data.msg == "200") {
                        this.questiondata = res.data.question_list;
                        this.question_list = res.data.question_list;
                        //this.courseauthoritylevels=res.data.courseauthoritylevels;
                        this.showSnackbar("#4caf50",res.data.message);
                        this.question_stmt=null
                        this.question_data=null
                        this.onload()
                        
                    } else {
                        this.message = res.data.message;
                    }
                    })
                    
                    .catch(error => {
                    //window.console.log("error:" + error);
                    })
                    .finally(() => (this.fetch_org_load = false));
                
                }
            }
            },
        
        savequestionMCQ()
            {
               //alert("Call"+this.inputs.value)
                if(this.selected_company==null)
                {
                    this.showSnackbar("#a61300","Select Company !!!");
                    //alert("Select Company")

                }else if(this.selected_round==null)
                {
                    //alert("Select Round")
                    this.showSnackbar("#a61300","Select Round !!!");
                }
                else if(this.question_stmt==null){
                    this.showSnackbar("#a61300","Enter Question Statement !!!");    
                }

                // else if(this.question_data==null){
                //     this.showSnackbar("#a61300","please select the checkbox !!!");   
                // }
                else{
                var formData = new FormData();
                const data = {
                    optionlist:this.inputs,
                    company_id:this.selected_company,
                    question_type_id:this.selected_type,
                    company_round_id:this.selected_round,
                    question_stmt:this.question_stmt,
                    question_data:this.question_data,
                }
               
                    // if(this.question_data==null){
                    //     this.showSnackbar("#a61300","Enter Question Answer !!!");
                    // }else{
                    //alert()
                   axios
                        .post("/LearnerData/saveQuestion", data)
                    .then(res => {
                    //window.console.log(res);
                    if (res.data.msg == "200") {
                        this.questiondata = res.data.question_list;
                        this.question_list = res.data.question_list;
                        //this.courseauthoritylevels=res.data.courseauthoritylevels;
                        this.showSnackbar("#4caf50",res.data.message);
                        this.question_stmt=null
                        this.question_data=null
                       // this.onload()
                        //this.selected_div='MCQ'
                        window.location.reload();
                        
                    } else if(res.data.msg == "no"){
                         this.showSnackbar("#b71c1c", res.data.message);
                    }else {
                        this.message = res.data.message;
                    }
                    })
                    
                    .catch(error => {
                    //window.console.log("error:" + error);
                    })
                    .finally(() => (this.fetch_org_load = false));
                }
               // }
            },
            onload()
            {
                axios
            .post("/LearnerData/AddQuestion")
            .then(res => {
                this.company_list = res.data.company_list
                this.round_list = res.data.company_round_list
                this.type_list = res.data.question_type_list
                this.status_list = res.data.question_status_list
                this.question_list = res.data.question_list
            })
            .catch(error => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
            })
            .finally(() => {

            });
            }
            
        
        },
 
        mounted() {
            this.onload()
        }
    };
</script>
<style scoped>
.del{
    margin: 7px;
    background-image: linear-gradient(-0deg, #f4faba, #ff0707);
color: #FFF;
}
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
.ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .btn-title{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
</style>